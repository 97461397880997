body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-amplify-authenticator][data-variation=modal] {
  background-color: #121212;
}

[data-amplify-authenticator] [data-amplify-router] {
  background: #1e1e1e;
  border: none;
  overflow: hidden;
  box-shadow: 0px 0px 10px #121212;
}

.amplify-tabs[data-indicator-position=top] {
  border: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  color: white;
  
  text-underline-offset: 7px;
}

.amplify-tabs-item[data-state=active] {
  color: white;
  font-weight: 300;
  text-decoration: underline;
}

[data-amplify-authenticator] [data-state=inactive] {
  background-color: #1e1e1e;
  color: white;
  font-weight: 300;
}

[data-amplify-authenticator] [data-state=inactive]:hover {
  text-decoration: underline;
}

.amplify-label {
  display: none;
}



.amplify-button {
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.amplify-button--primary {
  background: #303030;
  width:75%;
  margin: 25px auto 18px;
}

.amplify-button--primary:hover {
  /* background: #dfb774; */
  background: #404040;
  padding: 18px 0;
  margin: 23px auto 0px;
  width: 100%;
  /* color: #121212; */
}

.amplify-input {
  color: white;
}

.amplify-button--link {
  margin: 12px;
}

.amplify-button--link:hover {
  background: #404040;
  color: white;
  padding: 18px 55px;
  margin: 0px;
}


.amplify-input:hover {
  border-color: #bbb;
}

.amplify-input:focus {
  border-color: white;
}
.amplify-input--error {
  border-color: #e49492
}
.amplify-text--error {
  color: #e49492
}

[data-amplify-authenticator][data-variation=modal] {
  background-color: #1d1731;
  /* background: linear-gradient(334deg, #6b97f7, #7525e2, #f7137e); */
  background: linear-gradient(334deg, #07153d, #4797c4, #f7d347);
  background-size: 120% 120%;
  /* background-size: 180% 180%; */
}

.right-reserved {
  padding: 50px;
    color: #ddd;
    font-size: 10pt;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    font-weight: 200;
}